/*-------------------------------------------------------*/
/* Grid
/*-------------------------------------------------------*/

.section-wrap {
  padding: 70px 0;
  overflow: hidden;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .mobile & {
    background-position: 50% 50% !important;
    background-attachment: scroll !important;
  }

  @include bp-md-down {
    padding: 50px 0;
  }

  @include bp-sm-down {
    padding: 40px 0;
  }
}

.container-semi-fluid {
  padding: 0 50px;

  @include bp-md-down {
    padding: 0 15px;
  }
}

/* Flexbox
-------------------------------------------------------*/

.flex-parent {
  @include display-flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.flex-child {
  @include flex (1 0 0);
}


/* Columns With No Gutters
-------------------------------------------------------*/
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}


/* Row Gutters
-------------------------------------------------------*/
.row-8 {
  margin: 0 -4px;

  & > div {
    padding: 0 4px;
  }
}

.row-10 {
  margin: 0 -5px;

  & > div {
    padding: 0 5px;
  }
}

.row-20 {
  margin: 0 -10px;

  & > div {
    padding: 0 10px;
  }
}