/*-------------------------------------------------------*/
/* Hero
/*-------------------------------------------------------*/
.hero {
  margin-bottom: 60px;

  &__slide {
    height: 440px;

    @include bp-md-down {
      height: 360px;
    }
  }

  &__slide-entry {
    height: 100%;
    margin: 0 !important;
  }  
}

.thumb-container {
  background-color: #f7f7f7;
  position: relative;
  overflow: hidden;

  img {
    @include transition($all);
  }

  &:hover img {
    @include transform(scale(1.1,1.1));
  }
}

.thumb-text-holder {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 3;
}

.thumb-url {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.thumb-entry-title {
  color: #fff;
  font-size: 28px;
  margin-bottom: 42px;
  padding: 0 15px;

  &--sm {
    font-size: 18px;
  }

  &:hover a,
  a:focus {
    color: #fff;
  }
}

.thumb-bg-holder {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  @include transition($all);
}

.entry:hover .thumb-bg-holder {
  @include transform(scale(1.1));
}


/*-------------------------------------------------------*/
/* Featured Posts Slider
/*-------------------------------------------------------*/
.featured-posts-slider {
  margin-top: 40px;

  &__entry {
    margin-bottom: 0 !important;
  }

  &__text-holder {
    margin-top: 13px;
  }
  
  &__entry-title {
    font-size: 14px;
    line-height: 1.5;

    &:hover a {
      color: $main-color;
    }
  }
}


/*-------------------------------------------------------*/
/* Featured Posts Masonry
/*-------------------------------------------------------*/
.featured-posts-masonry {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: hidden;

  &__item {
    height: 360px;
    width: 100%;
    float: left;
  }

  &__item--lg {
    width: 66.669%;
  }

  &__item--sm {
    width: 33.333%;
  }

  &__entry {
    height: 100%;
    margin-bottom: 0 !important;
  }

  @include bp-md-down {
    &__item {
      width: 100%;
      height: 240px;
    }    
  }
}



/*-------------------------------------------------------*/
/* Blog
/*-------------------------------------------------------*/

.section-title {
  color: $main-color;
  margin-bottom: 20px;
  font-weight: 700;
}

.entry {
  overflow: hidden;
  position: relative;
  margin-bottom: 40px;

  &__img-holder {
    position: relative;
    overflow: hidden;
    margin-bottom: 27px;
  }

  &__img {
    width: 100%;
  }

  &__header {
    margin-bottom: 14px;
  }

  &__title {
    font-size: 18px;
    line-height: 1.4;
    margin-top: 3px;
    margin-bottom: 5px;
    font-weight: 600;

    @include bp-md-down {
      font-size: 22px;
    }

    &:hover a {
      color: $main-color;
    }
  }

  &__meta {

    li {
      display: inline-block;
      font-size: 13px;
      font-family: $heading-font;
      color: $meta-color;
    }

    a {
      color: $meta-color;

      &:hover {
        color: $main-color;
      }
    }

    li + li:before {
      content: '';
      width: 1px;
      background-color: #C5C5C5;
      height: 13px;
      margin-left: 7px;
      margin-right: 7px;
      display: inline-block;
      vertical-align: middle;
    }

    &-category {
      font-family: $heading-font;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: .03em;
      margin-bottom: 3px;
      font-weight: 700;
    }

    &-category--label {
      background-color: $main-color;
      padding: 5px 10px;
      color: #fff;
      border-radius: 5px;
      margin-bottom: 5px;
      display: inline-block;

      &:hover {
        color: #fff;
        opacity: 0.90;
      }
    }
  }

  &__excerpt p {
    margin-bottom: 0;
  }
}


/* List Post
-------------------------------------------------------*/
.post-list {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__img-holder {
    width: 255px;
    height: 100%;
    float: left;
    margin-right: 30px;
    margin-bottom: 0;

    a {
      display: block;
      height: 100%;
    }

    @include bp-lg-down {
      width: 170px;
    }
  }

  &__body {
    overflow: hidden;
  }

  @include bp-sm-down {
    &__img-holder,
    &__body {
      width: 100%;
    }

    &__img-holder {
      float: none;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

}


/*-------------------------------------------------------*/
/* Latest Videos
/*-------------------------------------------------------*/

.video-playlist {
  display: flex;

  &__content {
    width: 68%;

    & > div {
      height: 100%;
    }
  }

  &__list {
    width: 32%;
    border: 3px solid lighten($border-color, 7%);
  }

  &__list-item {
    display: table;
    width: 100%;
    line-height: 1;
    padding: 15px 14px;
    border-bottom: 1px solid $border-color;
    
    &:last-child {
      border-bottom: 0;
    }

    &:hover .video-playlist__list-item-title {
      color: $main-color;
    }

    &--active {
      background-color: #f4f6f6;
    }
  }

  &__list-item-thumb {
    float: left;
    width: 100px;
    margin-right: 13px;
  }

  &__list-item-description {
    overflow: hidden;
  }

  &__list-item-title {
    font-size: 14px;
    margin-top: 3px;
    line-height: 1.3;
  }

  @include bp-lg-down {
    display: block;

    &__list,
    &__content {
      width: 100%;
    }
    
  }
}


/*-------------------------------------------------------*/
/* Blog Single Post
/*-------------------------------------------------------*/

.single-post {
  &__entry-header {
    margin-bottom: 24px;
  }

  &__entry-title {
    margin-top: -5px;
    margin-bottom: 12px;
  }
}

.entry__article {

  &-holder {
    position: relative;
  }

  h1,h2,h3,h4,h5,h6 {
    margin-top: 36px;
  }

  & > p {
    margin-bottom: 34px;
    font-size: 17px;
    line-height: 28px;
  }

  figure {
    margin-bottom: 34px;
  }

  ol, ul {
    padding-left: 16px;
    line-height: 26px;
  }

  ul {
    list-style: disc;
  }
}

figure {
  margin: 0;
}

figcaption {
  font-family: $heading-font;
  font-size: 14px;
  margin-top: 10px;
  color: $meta-color;
}


/* Entry Share
/*-------------------------------------------------------*/

.entry__share {
  position: fixed;
  left: 0;
  top: 50%;
  @include transform(translateY(-50%));

  &-inner {
    border-radius: 0 4px 4px 0;
    overflow: hidden;
  }

  .socials .entry__share-social {
    display: block;
    margin: 0;
    border-radius: 0;
  }

  @media (max-width: 800px) {
    position: relative;
    margin-bottom: 20px;
    top: auto;
    @include transform(none);

    .socials .entry__share-social {
      display: inline-block;
      border-radius: 3px;
    }
  }
}


/* Entry Tags
/*-------------------------------------------------------*/

.entry__tags {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 24px 0;
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  font-family: $heading-font;

  a {
    color: $text-color;
    border-radius: 3px;
    padding: 2px 5px;
    margin-left: 5px;
    font-size: 12px;
    border: 1px solid $border-color;

    &:hover {
      background-color: $main-color;
      color: #fff;
      border-color: transparent;
    }
  }
}


/* Author
-------------------------------------------------------*/

.single-post__entry-meta {
  display: flex;
  align-items: center;

  li {
    margin-right: 16px;
  }

  li + li:before {
    display: none;
  }

  .entry__meta-date {
    margin-top: 2px;
  }

  .entry__meta-category {
    color: $text-color;
  }
}

.entry-author {
  position: relative;

  &__url {
    display: flex;
    align-items: center;

    span:first-of-type {
      margin-right: 5px;
    }
  }

  .entry-author__url:hover {
    color: $meta-color;
  }

  &__img {
    display: block;
    float: left;
    margin-right: 10px;
    border-radius: 50%;
  }

  &__name {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: $text-color;

    &:hover {
      color: $main-color;
    }
  }
}


/* Related Posts
-------------------------------------------------------*/
.related-posts {
  margin-top: 40px;

  &__entry {
    margin-bottom: 20px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 24px;
  }

  &__text-holder {
    margin-top: 14px;
  }

  &__entry-title {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 0;

    &:hover a {
      color: $main-color;
    }
  }
}


/* Comments
/*-------------------------------------------------------*/

.entry-comments__title {
  font-size: 24px;
}

.comment {

  @include bp-sm-up {
    .children {
      padding-left: 8%;
    }
  }

  &-list > li:first-child > .comment-body {
    border-top: 0;
  }

  &-body {
    margin-bottom: 24px;
    padding-top: 24px;
    border-top: 1px solid $border-color;
  }

  &-text {
    overflow: hidden;

    p {
      margin-bottom: 10px;
    }
  }

  &-avatar {
    float: left;
    margin-right: 20px;
    display: block;
    border-radius: 50%;
    overflow: hidden;
  }

  &-author {
    font-family: $heading-font;
    font-size: 16px;
    display: block;
    margin-bottom: 0;
  }

  &-date,
  &-edit-link {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 13px;
    font-family: $heading-font;
    color: $meta-color;
  }

  &-edit-link {
    margin-left: 5px;
    margin-bottom: 0;
    color: $main-color;
  }

  &-reply {
    color: $heading-color;
    font-family: $heading-font;

    &:hover {
      color: $main-color;
    }
  }
  
}


/* Comment Form
/*-------------------------------------------------------*/
.comment-form {
  p {
    margin: 0;
  }

  &__title {
    font-size: 20px;
  }
}

.comment-respond {
  margin-top: 40px;

  &__title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  &__subtitle {
    margin-bottom: 20px;
  }
}


/*-------------------------------------------------------*/
/* Sidebar
/*-------------------------------------------------------*/

.sidebar {

  .widget {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .widget-title {
    color: $main-color;
  }
}


@include bp-lg-up {

  .sidebar--right {
    padding-left: 65px;
  }

  .sidebar--left {
    padding-right: 65px;
  }

  .blog__content--right,
  .sidebar--right {
    float: right;
  }
  
}


/*-------------------------------------------------------*/
/* Widgets
/*-------------------------------------------------------*/

.widget {

  &-title {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .03em;
    margin-bottom: 18px;
    font-weight: 700;
  }

  .sub-menu {
    padding-left: 15px;
    padding-top: 5px;

    li {
      padding: 5px 0 !important;
    }
  }

  .children {
    padding-top: 10px;
    padding-left: 15px;
    margin-top: 10px;
  }
}


/* Widget Search
-------------------------------------------------------*/
.widget-search-input {
  background-color: #fff;
}

.widget-search-button,
.search-form__button {
  position: absolute;
  top: 0;
  height: 100%;
  width: 45px;
  padding: 0 !important;
  background-color: transparent;
  border: 0;
  right: 0;
  font-size: 17px;
  vertical-align: middle;
  border-radius: 0 4px 4px 0;
}

.search-form {
  position: relative;
}


/* Popular Posts
-------------------------------------------------------*/

.widget-popular-posts {

  &__list > li {
    padding: 14px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  &__img-holder {
    width: 65px;
    float: left;
    position: relative;
    margin-right: 15px;

    @include bp-sm-down {
      width: 50px;
    }
  }

  &__entry {
    min-height: 60px;
  }

  &__number {
    font-size: 15px;
    font-family: $heading-font;
    font-weight: 600;
    color: #fff;
    position: absolute;
    left: -15px;
    top: 50%;
    @include transform(translateY(-50%));
    width: 30px;
    height: 30px;
    line-height: 26px;
    border-radius: 50%;
    border: 2px solid #fff;
    text-align: center;
    background-color: $main-color;
    z-index: 2;
  }

  &__entry-title {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 0;
    padding-left: 26px;

    a {
      color: $heading-color;
    }

    a:hover {
      color: $main-color;
    }
  }
}


/* Categories
-------------------------------------------------------*/

.widget_categories li {
  padding: 9px 0;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: $meta-color;


  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  a {
    margin-right: 4px;
    color: $text-color;

    &:hover {
      color: $main-color;
    }
  }
}


/* Widget Newsletter 
-------------------------------------------------------*/

.widget_mc4wp_form_widget {
  border: 5px solid lighten($border-color, 7%);
  padding: 28px 24px;

  .widget-title {
    text-transform: none;
    letter-spacing: normal;
    color: $heading-color;
    font-size: 15px;
  }
}

.mc4wp-form-fields {
  position: relative;
}

.mc4wp-form-fields p {
  margin-bottom: 0;
  position: relative;
}

.mc4wp-form-icon {
  position: absolute;
  line-height: 40px;
  left: 16px;
  color: #9E9E9E;
}

.mc4wp-form-fields input[type=email]:before {
  content: '';
  display: block;
}

.mc4wp-form-fields input[type=email],
.mc4wp-form-fields input[type=text] {
  margin-bottom: 8px;
  padding-left: 40px;
}

.mc4wp-form-fields input[type=submit] {
  margin-bottom: 0;
  width: auto;
}

/* Widget Newsletter Wide
-------------------------------------------------------*/
.newsletter-wide {
  overflow: hidden;

  &__text {
    width: 40%;
    float: left;
    padding-right: 10px;
  }

  .widget-title {
    margin-bottom: 0;
  }

  .mc4wp-form-fields {
    display: flex;
  }

  &__form {
    width: 60%;
    float: right;

    input {
      width: 67%;
      margin-bottom: 0 !important;
    }

    input[type=submit] {
      width: 38%;
      margin-left: 15px;
      vertical-align: top;
    }
  }

  @include bp-xl-down {
    &__form input {
      width: 60%;
    }

    input[type=submit] {
      width: 39%;
    }
  }

  @include bp-lg-down {

    &__text,
    &__form {
      width: 100%;
    }

    &__text {
      margin-bottom: 10px;
    }  
    
    &__form input {
      margin-left: 0;
    }
  }

  @include bp-sm-down {
    .mc4wp-form-fields {
      display: block;
    }

    &__form input {
      width: 100%;
    }

    &__form input[type=submit] {
      margin-left: 0;
      margin-top: 10px;
    }
  }

}

/* Widget Socials
-------------------------------------------------------*/
.widget-socials {

  li {
    padding: 0 0 6px;
  }

  .socials a {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 5px;
    text-align: left;
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    height: 40px;
    padding: 0 15px;
    font-family: $heading-font;
    font-weight: 600;

    &:hover {
      color: #fff;
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 100%;
      background-color: rgba(#fff,.1);
      position: absolute;
      left: 0;
      @include transition($all);
    }

    &:hover:before {
      width: 100%;
    }
  }

  .socials i {
    font-size: 15px;
    width: 20px;
    display: inline-block;
  }
}


/* Recent Posts
-------------------------------------------------------*/

.widget_recent_entries {

  li {
    padding-bottom: 7px;
    margin-bottom: 25px;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: 0;
    }
  }

  li a {
    display: block;
    color: $heading-color;

    &:hover {
      color: $main-color;
    }
  }

  span {
    font-size: 10px;
  }
}


/* Widget Nav Menu
-------------------------------------------------------*/
.widget_nav_menu {
  li {
    line-height: 26px;
    font-size: 14px;
  }
}

/* Widget Twitter
-------------------------------------------------------*/
.tweet {
  word-wrap: break-word;
  margin-bottom: 20px;
}


/* Tags
-------------------------------------------------------*/

.widget_tags a {
  padding: 7px 10px;
  line-height: 1;
  margin: 0 10px 10px 0;
  font-size: 13px;
  border: 1px solid $border-color;
  color: $text-color;
  display: inline-block;
  float: left;

  &:hover {
    background-color: $main-color;
    border-color: transparent;
    color: #fff;
  }
}


/*-------------------------------------------------------*/
/* Page title
/*-------------------------------------------------------*/
.page-title {
  font-size: 28px;
  margin-bottom: 24px;
}


/*-------------------------------------------------------*/
/* Pagination
/*-------------------------------------------------------*/

.pagination {

  &__nav {
    @include display-flex;
    justify-content: center;
  }

  &__page {
    font-size: 15px;
    width: 32px;
    height: 32px;
    line-height: 30px;
    margin: 0 2px;
    text-align: center;
    border: 1px solid $border-color;
    color: $heading-color;
    vertical-align: middle;

    &:not(span):hover {
      background-color: $main-color;
      border-color: transparent;
      color: #fff;
    }

    i {
      font-size: 18px;
    }

    &--current {
      background-color: #000;
      color: #fff;
      border-color: transparent;
    }
  }
}


/*-------------------------------------------------------*/
/* About Page
/*-------------------------------------------------------*/



/*-------------------------------------------------------*/
/* Contact Page
/*-------------------------------------------------------*/
.contact {
  &__img {
    margin-bottom: 34px;
  }
}

.contact-name,
.contact-email,
.contact-subject {
  max-width: 266px;  
}


/*-------------------------------------------------------*/
/* 404
/*-------------------------------------------------------*/

.page-404 {
  text-align: center;
  padding: 130px 0;

  @include bp-sm-down {
    padding: 70px 0;
  }

  &__title {
    font-size: 334px;
    letter-spacing: 0.03em;
    color: #b2b2b2;
    line-height: .75;

    @include bp-md-down {
      font-size: 200px;
    }

    @include bp-sm-down {
      font-size: 120px;
    }
    
  }

  &__subtitle {
    font-size: 50px;

    @include bp-md-down {
      font-size: 36px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 32px;
    max-width: 636px;
    margin: 0 auto 25px auto;
  }
}